import { useStores } from '@contexts/store/RootStoreContext';
import { HTTP_AUTH_CODES } from '@models/constants/API';
import { NETWORK_EVENTS } from '@models/constants/Events';
import { ResponseError } from '@models/error/network';
import EventBus from '@services/events/EventBus';
import notification from 'antd/lib/notification';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

interface NetworkErrorManagerProps {}

/**
 * Handles network error side effects in the React Lifecycle
 */
const NetworkErrorManager: FunctionComponent<NetworkErrorManagerProps> = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const rootStore = useStores();
  const { t } = useTranslation();
  const toastVisibility = useRef(false);

  const eventSubscriber = useCallback(
    (error: ResponseError) => {
      if (HTTP_AUTH_CODES.includes(error.statusCode)) {
        if (location.pathname !== '/login') {
          const errorDetail = (error as ResponseError).responseBody?.detail;
          const detailDescription = errorDetail ? ` (${errorDetail})` : '';
          // eslint-disable-next-line no-console
          console.warn(
            `Received Auth Network Error - Status: ${error.statusCode}${detailDescription}, returning to login page & logging out...`
          );
          rootStore.logout();
          navigate('/login', { state: { errorCode: error.statusCode, detail: errorDetail } });
        }
      } else {
        if (!toastVisibility.current) {
          toastVisibility.current = true;
          notification.error({
            message: t('network.error.title'),
            description: t('network.error.description', {
              errorCode: `${error.statusCode}${error.sentryId ? `-${error.sentryId}` : ''}`,
            }),
            placement: 'bottomRight',
            duration: 10,
            onClose: () => {
              toastVisibility.current = false;
            },
          });
        }
      }
    },
    [navigate, location.pathname, rootStore, t]
  );

  useEffect(() => {
    const unsubNetErrorEventListener = EventBus.on(NETWORK_EVENTS.ERROR, eventSubscriber);

    return () => {
      unsubNetErrorEventListener();
    };
  }, [eventSubscriber]);

  return null;
});

export default NetworkErrorManager;
