import { UserOrganization } from '@models/types/Configuration';
import { BackendPermissions, PermissionApp } from '@models/types/Permission';
import { ServicesConfiguration } from '@models/types/ServicesConfiguration';
import {
  getEnabledAdministrationFeatures,
  getEnabledApplicationFeatures,
  permissionsForFeature,
} from '@utils/Permission';

const nonAdminFeatureGroups: string[] = [PermissionApp.Organizations];
const organizationBoundWriteFeatureGroups: string[] = [PermissionApp.DocumentRequests];

export const AdministrationOrganizationId = -1;
export const AdministrationOrganizationName = 'Administration';

export const getAdministrationOrganization = (servicesConfiguration: ServicesConfiguration): UserOrganization => {
  const allAppPermissions = getEnabledApplicationFeatures(servicesConfiguration).flatMap((group) => {
    if (nonAdminFeatureGroups.includes(group.id)) {
      return [];
    }
    const readonly = organizationBoundWriteFeatureGroups.includes(group.id);
    return group.features.flatMap((feature) => permissionsForFeature(feature, group.id, readonly));
  });

  const superAdminPermissions = getEnabledAdministrationFeatures(servicesConfiguration).flatMap((group) => {
    return [
      BackendPermissions.AdministrationDashboardRead,
      ...group.features.flatMap((feature) => permissionsForFeature(feature, group.id)),
    ];
  });

  return {
    id: AdministrationOrganizationId,
    name: AdministrationOrganizationName,
    admin: true,
    permissions: [...superAdminPermissions, ...allAppPermissions],
  };
};
