/* istanbul ignore file */

import { QUERY_STALE_TIME } from '@models/constants/API';
import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: QUERY_STALE_TIME,
    },
  },
});

export default queryClient;
