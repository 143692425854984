import { ConfigurationStoreModel, IConfigurationStore } from '@models/store/configuration/ConfigurationStoreModel';
import { StoreEnv } from '@store/Environment';

export const setupConfigurationStore = (env: StoreEnv) => {
  let configurationStore: IConfigurationStore;
  const initData = {
    admin: false,
    orgAdmin: false,
    documentIds: [],
    servicesConfiguration: {
      documentsEnabled: false,
      documentRequestsEnabled: false,
      verificationsEnabled: false,
      newsEnabled: false,
      externalLinksEnabled: false,
      pollsEnabled: false,
      voteInfoEnabled: false,
      siteAccessEnabled: false,
      formGroupsEnabled: false,
      relyingPartiesEnabled: false,
    },
  };

  configurationStore = ConfigurationStoreModel.create(initData, env);

  return configurationStore;
};
