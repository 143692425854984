import { useStores } from '@contexts/store/RootStoreContext';
import { useConfiguration } from '@hooks/api/Configuration';
import { LoadingView } from '@procivis/react-components';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router-dom';

export interface DataGuardedRouteProps {}

const DataGuardedRoute: FunctionComponent<PropsWithChildren<DataGuardedRouteProps>> = observer(({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [failureTimeout, setFailureTimeout] = useState(false);
  const rootStore = useStores();
  const { configurationStore } = rootStore;

  const {
    data: configurationData,
    isError: isConfigError,
    isSuccess: isConfigSuccess,
    failureCount: configFailureCount,
  } = useConfiguration();

  useEffect(() => {
    if (!isConfigError) {
      return;
    }
    rootStore.logout();
    navigate('/login', { state: { errorCode: 404 } });
  }, [isConfigError, navigate, rootStore]);

  // Setup Configuration
  useEffect(() => {
    if (isConfigSuccess && configurationData) {
      configurationStore.setConfiguration(configurationData);
    }
  }, [configurationData, configurationStore, isConfigSuccess]);

  // Failure Fallback
  useEffect(() => {
    let failureTimer = setTimeout(() => setFailureTimeout(true), 3000);
    return () => {
      clearTimeout(failureTimer);
    };
  }, []);

  const isError = isConfigError;
  const shouldFail = configFailureCount >= 3;

  return configurationStore.isConfigured ? (
    <>{children}</>
  ) : (isError && shouldFail) || failureTimeout ? (
    <Navigate to={{ pathname: '/login' }} state={{ from: location }} />
  ) : (
    <LoadingView />
  );
});

export default DataGuardedRoute;
