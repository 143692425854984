import { useStores } from '@contexts/store/RootStoreContext';
import LazyRouteElement from '@router/LazyRouteElement';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export interface MainGuardedRouteProps {}

const MainGuardedRoute: FunctionComponent<PropsWithChildren<MainGuardedRouteProps>> = observer(({ children }) => {
  const location = useLocation();
  const { networkStore } = useStores();
  const [currentPermission, setCurrentPermission] = useState(networkStore.isLoggedIn);

  useEffect(() => {
    if (networkStore.isLoggedIn !== currentPermission) {
      setCurrentPermission(networkStore.isLoggedIn);
    }
    if (!networkStore.isLoggedIn) {
      networkStore.logout();
    }
  }, [networkStore, currentPermission]);

  return (
    <LazyRouteElement>
      {currentPermission ? children : <Navigate state={{ from: location }} to="/login" />}
    </LazyRouteElement>
  );
});

export default MainGuardedRoute;
