import Logo from '@components/branding/Logo/Logo.adapter';
import { FullScreenView, Space, VerticalSegment } from '@procivis/react-components';
import AntTypography from 'antd/lib/typography';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SmallScreenPlaceholder.module.css';

export interface SmallScreenPlaceholderProps {}

/**
 * Small Screen Placeholder
 */
const SmallScreenPlaceholder: FunctionComponent<PropsWithChildren<SmallScreenPlaceholderProps>> = ({ children }) => {
  const { t } = useTranslation(); // TODO: Fix typing of `t` function (https://www.i18next.com/overview/typescript)
  return (
    <FullScreenView className={styles.container}>
      <Space direction="vertical" align="center">
        <Logo />
        <VerticalSegment centered>
          {(t('layout.smallScreen.placeholder') as string).split('\n').map((text) => (
            <AntTypography.Text type="secondary">{text}</AntTypography.Text>
          ))}
        </VerticalSegment>
      </Space>
    </FullScreenView>
  );
};

export default SmallScreenPlaceholder;
