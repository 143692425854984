import { UserAuditEventDTO } from '@models/dtos/UserHistory';
import { EventHistoryAction, HistoryEvent } from '@models/types/HistoryEvent';

const userHistoryActionParser = (status: number): EventHistoryAction => {
  switch (status) {
    case 0:
      return EventHistoryAction.ProfileCreated;
    case 1:
      return EventHistoryAction.ValidationCreated;
    case 2:
      return EventHistoryAction.ValidationRejected;
    case 3:
      return EventHistoryAction.ValidationAccepted;
    case 4:
      return EventHistoryAction.ValidationRevoked;
    case 5:
      return EventHistoryAction.ValidationReinstated;
    case 6:
      return EventHistoryAction.ProfileUpdated;
    case 7:
      return EventHistoryAction.ProfileMigrated;
    case 8:
      return EventHistoryAction.ValidationExtended;
    default:
      return EventHistoryAction.Unknown;
  }
};

export const userHistoryEventDTOParser = (userAuditEventDTO: UserAuditEventDTO): HistoryEvent => {
  return {
    id: userAuditEventDTO.event_id,
    action: userHistoryActionParser(userAuditEventDTO.event),
    datetime: new Date(Date.parse(userAuditEventDTO.created_at)),
    employee: userAuditEventDTO.event_initiator,
  };
};

export const userDetailsDTOAuditListParser = (userAuditEventList: UserAuditEventDTO[]): HistoryEvent[] => {
  return userAuditEventList.map((eventDTO: UserAuditEventDTO) => userHistoryEventDTOParser(eventDTO));
};
