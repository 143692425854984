import { AdministrationFeatureGroup, FeatureGroup } from '@models/types/Feature';
import { PermissionApp, PermissionName } from '@models/types/Permission';

export const applicationFeatures: FeatureGroup[] = [
  {
    id: PermissionApp.Organizations,
    sectionTitle: 'features.organizations',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.User, titleKey: 'features.organizations.user' }],
  },
  {
    id: PermissionApp.Documents,
    featureFlag: 'documentsEnabled',
    sectionTitle: 'features.documents',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.Request, titleKey: 'features.documents.order' }],
  },
  {
    id: PermissionApp.DocumentRequests,
    featureFlag: 'documentRequestsEnabled',
    sectionTitle: 'features.documentRequests',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.DeliveryRequest, titleKey: 'features.documentRequests.request' }],
  },
  {
    id: PermissionApp.Validation,
    featureFlag: 'verificationsEnabled',
    sectionTitle: 'features.validation',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.Validation, titleKey: 'features.validation.validation' }],
  },
  {
    id: PermissionApp.VoteInfo,
    featureFlag: 'voteInfoEnabled',
    sectionTitle: 'features.voteinfo',
    optionsTitle: 'features.permission',
    features: [
      { id: PermissionName.Voteday, titleKey: 'features.voteinfo.voteday' },
      { id: PermissionName.Proposal, titleKey: 'features.voteinfo.proposal', writeOnly: true },
      { id: PermissionName.Excuse, titleKey: 'features.voteinfo.excuse', readOnly: true },
    ],
  },
  {
    id: PermissionApp.News,
    featureFlag: 'newsEnabled',
    sectionTitle: 'features.news',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.News, titleKey: 'features.news.news' }],
  },
  {
    id: PermissionApp.ExternalLinks,
    featureFlag: 'externalLinksEnabled',
    sectionTitle: 'features.externalLinks',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.ExternalLink, titleKey: 'features.externalLinks.externalLink' }],
  },
  {
    id: PermissionApp.Polls,
    featureFlag: 'pollsEnabled',
    sectionTitle: 'features.voting',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.Poll, titleKey: 'features.voting.vote' }],
  },
  {
    id: PermissionApp.Access,
    featureFlag: 'siteAccessEnabled',
    sectionTitle: 'features.siteAccess',
    optionsTitle: 'features.permission',
    features: [
      { id: PermissionName.Access, titleKey: 'features.access.access' },
      { id: PermissionName.PublicAccess, titleKey: 'features.access.publicAccess' },
    ],
  },
  {
    id: PermissionApp.FormGroups,
    featureFlag: 'formGroupsEnabled',
    sectionTitle: 'features.forms',
    optionsTitle: 'features.permission',
    features: [
      { id: PermissionName.FormGroups, titleKey: 'features.forms.formGroups' },
      { id: PermissionName.Forms, titleKey: 'features.forms.forms' },
    ],
  },
];

export const administrationFeatures: AdministrationFeatureGroup[] = [
  {
    id: PermissionApp.AdministrationOrganizations,
    sectionTitle: 'features.organizations',
    optionsTitle: 'features.permission',
    features: [
      { id: PermissionName.Organization, titleKey: 'features.organizations.organization' },
      { id: PermissionName.User, titleKey: 'features.organizations.user' },
      { id: PermissionName.Role, titleKey: 'features.organizations.role' },
    ],
  },
  {
    id: PermissionApp.AdministrationRelyingParties,
    featureFlag: 'relyingPartiesEnabled',
    sectionTitle: 'features.relyingParties',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.RelyingParty, titleKey: 'features.relyingParties.relyingParty' }],
  },
  {
    id: PermissionApp.AdministrationAuditLogs,
    sectionTitle: 'features.auditLogs',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.AuditLogs, titleKey: 'features.auditLogs' }],
  },
  {
    id: PermissionApp.AdministrationDocumentRequests,
    featureFlag: 'documentRequestsEnabled',
    sectionTitle: 'features.documentRequests',
    optionsTitle: 'features.permission',
    features: [{ id: PermissionName.DeliveryRequest, titleKey: 'features.documentRequests.request', writeOnly: true }],
  },
];
