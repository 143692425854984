import { Configuration, UserOrganization } from '@models/types/Configuration';
import { ServicesConfiguration } from '@models/types/ServicesConfiguration';
import { cast, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

const organizationType = types.custom<string, UserOrganization>({
  name: 'Organization',
  fromSnapshot(value) {
    return JSON.parse(value) as UserOrganization;
  },
  toSnapshot(organization) {
    return JSON.stringify(organization);
  },
  isTargetType(value) {
    return typeof value !== 'string';
  },
  getValidationMessage(value: string): string {
    try {
      const object = this.fromSnapshot(value);
      if (object.id && object.name && object.permissions) {
        return '';
      }
    } catch {}
    return `${value} is not a valid OrganizationConfiguration snapshot`;
  },
});

const servicesConfigurationType = types.custom<string, ServicesConfiguration>({
  name: 'ServicesConfiguration',
  fromSnapshot(value) {
    return JSON.parse(value) as ServicesConfiguration;
  },
  toSnapshot(organization) {
    return JSON.stringify(organization);
  },
  isTargetType(value) {
    return typeof value !== 'string';
  },
  getValidationMessage(value: string): string {
    try {
      this.fromSnapshot(value);
      return '';
    } catch {}
    return `${value} is not a valid ServicesConfiguration snapshot`;
  },
});

export const ConfigurationStoreModel = types
  .model('ConfigurationStore', {
    _organizations: types.maybe(types.array(organizationType)),
    _permissions: types.array(types.string),
    admin: types.boolean,
    orgAdmin: types.boolean,
    time: types.maybe(types.Date),
    documentIds: types.array(types.number),
    servicesConfiguration: servicesConfigurationType,
  })
  // Sync Actions
  .actions((self) => ({
    setTime: (time: Date | undefined) => {
      self.time = time;
    },
    setConfiguration: (configuration: Configuration) => {
      self._organizations = cast(configuration.organizations);
      self.documentIds.replace(configuration.documentIds);
      self.servicesConfiguration = configuration.features;
      self.admin = configuration.isSuperAdmin;
    },
    setPermissions: (permissions: string[]) => {
      self._permissions.replace(permissions);
    },
    setOrgAdmin: (isOrgAdmin: boolean) => {
      self.orgAdmin = isOrgAdmin;
    },
    logout: () => {
      self._organizations = undefined;
      self._permissions.clear();
      self.documentIds.clear();
      self.servicesConfiguration = {
        documentsEnabled: false,
        documentRequestsEnabled: false,
        verificationsEnabled: false,
        newsEnabled: false,
        externalLinksEnabled: false,
        pollsEnabled: false,
        voteInfoEnabled: false,
        siteAccessEnabled: false,
        formGroupsEnabled: false,
        relyingPartiesEnabled: false,
      };
      self.admin = false;
      self.orgAdmin = false;
    },
  }))
  // Derived values
  .views((self) => ({
    get isConfigured() {
      const hasConfig = self._organizations !== undefined;

      return hasConfig;
    },
    get organizations() {
      return self._organizations?.slice();
    },
    get permissions() {
      return self._permissions.slice();
    },
  }));

export interface IConfigurationStore extends Instance<typeof ConfigurationStoreModel> {}
export interface IConfigurationStoreSnapshot extends SnapshotOut<typeof ConfigurationStoreModel> {}
export interface IConfigurationStoreIn extends SnapshotIn<typeof ConfigurationStoreModel> {}
