import { useStores } from '@contexts/store/RootStoreContext';
import { useServerTime } from '@hooks/api/Configuration';
import { LoadingView } from '@procivis/react-components';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';

const TimeProviderWrapper: FunctionComponent<PropsWithChildren> = observer(({ children }) => {
  const { configurationStore } = useStores();
  const { refetch: fetchTime } = useServerTime();

  useEffect(() => {
    if (configurationStore.time !== undefined) {
      return;
    }
    fetchTime().then(({ data: time }) => {
      configurationStore.setTime(time);
    });
  }, [configurationStore, fetchTime]);

  if (!configurationStore.time) {
    return <LoadingView />;
  } else {
    return <>{children}</>;
  }
});

export default TimeProviderWrapper;
