import { ThemeColors, ThemeImages } from '@appconf/models';
import SHBg from '@assets/images/SHBackground.svg';
import SHLogo from '@assets/images/SHLogo.svg';
import { ThemeConfig as AntThemeConfig } from 'antd/lib/config-provider/context';

export const shThemeColors: ThemeColors = {
  neutral: '#ffffff',
  primary: '#ffdc00',
  primaryVariant: '#cfac1e',
  onPrimary: '#000000',
  onPrimaryVariant: '#000000',
  text: 'rgba(0, 0, 0, 0.85)',
  background: '#f0f2f5',
  componentBackground: '#ffffff',
  orgHighlight: 'orange',
  orgAdminHighlight: 'red',
};

export const shThemeImages: ThemeImages = {
  loginBgURI: SHBg,
  logoURI: SHLogo,
};

export const shThemeConfig: AntThemeConfig = {
  token: {
    borderRadius: 2,
    colorBgBase: '#f0f2f5',
    colorBgContainer: '#ffffff',
    colorBgElevated: '#ffffff',
    colorBgLayout: '#f0f2f5',
    colorInfo: '#ffdc00',
    colorLink: '#cfac1e',
    colorPrimary: '#ffdc00',
    colorText: 'rgba(0, 0, 0, 0.85)',
  },
  components: {
    Menu: {
      itemSelectedBg: '#f5f5f5',
    },
    Descriptions: {
      colorSplit: '#f0f0f0',
    },
    Dropdown: {
      colorPrimaryBg: '#cfac1e',
    },
    Select: {
      optionSelectedBg: '#cfac1e',
    },
    Table: {
      rowSelectedBg: '#cfac1e',
      rowSelectedHoverBg: '#c5a41e',
    },
  },
};
