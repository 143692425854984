import { MunicipalityDTO } from '@models/dtos/Municipality';
import { Municipality } from '@models/types/Municipality';

export const municipalityDTOParser = (municipalityDTO: MunicipalityDTO): Municipality => {
  return {
    id: municipalityDTO.id,
    name: municipalityDTO.name,
    bfsnr: municipalityDTO.bfsnr,
  };
};
