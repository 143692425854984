import { administrationFeatures, applicationFeatures } from '@models/constants/ApplicationFeatures';
import { Feature, FeatureGroup } from '@models/types/Feature';
import { BackendPermissionAction, Permission, PermissionType } from '@models/types/Permission';
import { ServicesConfiguration } from '@models/types/ServicesConfiguration';

export const getEnabledApplicationFeatures = (servicesConfiguration: ServicesConfiguration) => {
  return applicationFeatures.filter((group) => (group.featureFlag ? servicesConfiguration[group.featureFlag] : true));
};

export const getEnabledAdministrationFeatures = (servicesConfiguration: ServicesConfiguration) => {
  return administrationFeatures.filter((group) =>
    group.featureFlag ? servicesConfiguration[group.featureFlag] : true
  );
};

export const permissionTypesForFeature = (feature: Feature): PermissionType[] => {
  const permissionTypes = [PermissionType.None];
  if (!feature.writeOnly) {
    permissionTypes.push(PermissionType.Read);
  }
  if (!feature.readOnly) {
    permissionTypes.push(PermissionType.Edit);
  }
  return permissionTypes;
};

export const permissionsForFeature = (feature: Feature, groupId: string, readonly = false): string[] => {
  const permissions: string[] = [];
  if (!feature.writeOnly) {
    permissions.push(`${groupId}.${feature.id}.${BackendPermissionAction.Read}`);
  }
  if (!feature.readOnly && !readonly) {
    permissions.push(`${groupId}.${feature.id}.${BackendPermissionAction.Edit}`);
  }
  return permissions;
};

export const enabledFeatureGroupForPermission = (
  permission: Permission,
  servicesConfiguration: ServicesConfiguration
): FeatureGroup | undefined => {
  const group = getEnabledApplicationFeatures(servicesConfiguration).find(
    (featureGroup) => featureGroup.id === permission.app
  );
  return group;
};

export const featureGroupForPermission = (permission: Permission): FeatureGroup | undefined => {
  const group = applicationFeatures.find((featureGroup) => featureGroup.id === permission.app);
  return group;
};

export const featureForPermission = (permission: Permission): Feature | undefined => {
  const group = featureGroupForPermission(permission);
  const feature = group?.features.find((feature) => feature.id === permission.name);
  return feature;
};
