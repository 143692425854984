export const LOGIN_PATH = '/api/v1/auth/login/';
export const LOGOUT_PATH = '/api/v1/auth/logout/';
export const UNLOCK_ACCOUNT_PATH = '/api/v1/auth/unblock-account/';
export const RESET_PASSWORD_PATH = '/api/v1/auth/password-reset/';
export const RESET_PASSWORD_CONFIRM_PATH = '/api/v1/auth/password-reset/confirm/';
export const LOGIN_EID_INIT_PATH = '/api/v1/auth/oidc/init/';
export const LOGIN_EID_CB_PATH = '/api/v1/auth/oidc/callback/';
export const VERIFICATIONS_PATH = '/api/v1/verification/';
export const VERIFICATION_DETAILS_PATH = (id: number) => `/api/v1/verification/${id}/`;
export const VERIFICATION_NEW_INIT_PATH = '/api/v1/verification/oidc/profile-validation/init/';
export const VERIFICATION_NEW_CB_PATH = '/api/v1/verification/oidc/profile-validation/callback/';
export const VERIFICATION_NEW_USER_PATH = (id: string) => `/api/v1/verification/profile/${id}/new/`;
export const DOCUMENTS_PATH = '/api/v1/documents/';
export const DOCUMENT_DETAILS_PATH = (id: number) => `/api/v1/documents/${id}/`;
export const DOCUMENT_CANCEL_PATH = (id: number) => `/api/v1/documents/${id}/cancel/`;
export const DOCUMENT_REQUESTS_PATH = '/api/v1/delivery-request/';
export const DOCUMENT_REQUEST_DETAILS_PATH = (id: number) => `/api/v1/delivery-request/${id}/`;
export const DOCUMENT_REQUEST_WITHDRAW_PATH = (id: number) => `/api/v1/delivery-request/${id}/cancel`;
export const DOCUMENT_REQUEST_DOCUMENT_DOWNLOAD_PATH = (id: string) => `/api/v1/delivery-request/download/${id}`;
export const DOCUMENT_REQUEST_DOWNLOAD_PATH = (id: number) => `/api/v1/delivery-request/${id}/download`;
export const DOCUMENTS_REPORT_PATH = '/api/v1/documents/report/';
export const NEWS_ARTICLES_PATH = '/api/v1/news/';
export const NEWS_ARTICLES_DETAILS_PATH = (id: number) => `/api/v1/news/${id}/`;
export const NEWS_ARTICLES_CONTENT_PATH = (articleId: number) => `/api/v1/news/${articleId}/content/`;
export const NEWS_ARTICLES_CONTENT_DETAILS_PATH = (articleId: number, contentId: number) =>
  `/api/v1/news/${articleId}/content/${contentId}/`;
export const POLLS_PATH = '/api/v1/polls/';
export const POLL_DETAILS_PATH = (id: number) => `/api/v1/polls/${id}/`;
export const POLL_EXPORT_PATH = (id: number) => `/api/v1/polls/${id}/export/`;
export const POLL_PARTICIPANTS_PATH = (id: number) => `/api/v1/polls/${id}/participants/`;
export const VERIFIED_CITIZENS_PATH = '/api/v1/verification/profile/validated';
export const VOTES_PATH = '/api/v1/voteinfo/voteday/';
export const VOTE_DETAILS_PATH = (id: number) => `/api/v1/voteinfo/voteday/${id}/`;
export const VOTE_PROPOSALS_PATH = (id: number) => `/api/v1/voteinfo/voteday/${id}/proposals/`;
export const VOTE_PROPOSAL_DETAILS_PATH = (id: number) => `/api/v1/voteinfo/proposal/${id}/`;
export const VOTE_PROPOSAL_PDF_PATH = (id: number | string) => `/api/v1/voteinfo/proposal/${id}/pdf/`;
export const VOTE_ABSENCES_PATH = (id: number) => `/api/v1/voteinfo/voteday/${id}/excuses/`;
export const VOTE_ABSENCES_REPORT = `/api/v1/voteinfo/report/`;
export const STATISTICS_OVERVIEW_PATH = '/api/v1/reports/kpi/overview/';
export const STATISTICS_DOCUMENTS_PATH = '/api/v1/reports/kpi/document-requests/';
export const STATISTICS_VERIFICATIONS_PATH = '/api/v1/reports/kpi/verifications/';
export const STATISTICS_USAGE_PATH = '/api/v1/reports/kpi/usage/';
export const ORGANIZATIONS_PATH = '/api/v1/organisation/';
export const ORGANIZATION_DETAILS_PATH = (id: number) => `/api/v1/organisation/${id}/`;
export const ORGANIZATION_DUPLICATE_PATH = '/api/v1/organisation/duplicate/';
export const ORGANIZATION_DOCUMENT_TYPE_PATH = (id: number) => `/api/v1/organisation/${id}/document_type/`;
export const ORGANIZATION_DOCUMENT_TYPE_DETAILS_PATH = (orgId: number, typeId: number) =>
  `/api/v1/organisation/${orgId}/document_type/${typeId}/`;
export const ORGANIZATION_ROLE_PATH = (id: number) => `/api/v1/organisation/${id}/role/`;
export const ORGANIZATION_ROLE_DETAILS_PATH = (orgId: number, roleId: number) =>
  `/api/v1/organisation/${orgId}/role/${roleId}/`;
export const ORGANIZATION_EMPLOYEES_PATH = (orgId: number) => `/api/v1/organisation/${orgId}/user/`;
export const ORGANIZATION_EMPLOYEE_DETAILS_PATH = (orgId: number, userId: number) =>
  `/api/v1/organisation/${orgId}/user/${userId}/`;
export const ORGANIZATION_EMPLOYEE_ROLE_PATH = (orgId: number, userId: number) =>
  `/api/v1/organisation/${orgId}/user/${userId}/role/`;
export const ROLES_PATH = '/api/v1/role/';
export const ROLE_DETAILS_PATH = (id: number) => `/api/v1/role/${id}/`;
export const PERMISSIONS_PATH = '/api/v1/permission/';
export const EMPLOYEES_PATH = '/api/v1/user/';
export const EMPLOYEE_DETAILS_PATH = (id: number) => `/api/v1/user/${id}/`;
export const EMPLOYEE_PICTURE_UPLOAD_PATH = (id: number) => `/api/v1/user/${id}/photo`;
export const EMPLOYEE_LANGUAGE_PATH = (id: number) => `/api/v1/user/${id}/language/`;
export const RELYING_PARTY_PATH = '/api/v1/relying-parties/';
export const RELYING_PARTY_DETAILS_PATH = (id: number) => `/api/v1/relying-parties/${id}/`;
export const RELYING_PARTY_GENERATE_API_KEYS_PATH = (id: number) => `/api/v1/relying-parties/${id}/generate-keys/`;
export const RELYING_PARTY_GENERATE_PUSH_SECRET_PATH = (id: number) =>
  `/api/v1/relying-parties/${id}/generate/push-key/`;
export const RELYING_PARTY_CREATE_PATH = '/api/v1/relying-parties/';
export const MUNICIPALITES_PATH = '/api/v1/municipality/';
export const EXTERNAL_LINKS_PATH = '/api/v1/external/links/';
export const EXTERNAL_LINKS_SET_ORDER_PATH = '/api/v1/external/links/set-ordering/';
export const EXTERNAL_LINK_DETAILS_PATH = (id: number) => `/api/v1/external/links/${id}/`;
export const EMPLOYEES_EXPORT_CSV = '/api/v1/user/csv_export/';
export const EMPLOYEES_INVITE_CHECK_PATH = '/api/v1/user/invite/check/';
export const EMPLOYEES_INVITE_ACCEPT_PATH = '/api/v1/user/invite/verify/';
export const EMPLOYEES_INVITE_RESEND_PATH = '/api/v1/user/invite/resend/';
export const EMPLOYEES_INVITE_EID_INIT_PATH = '/api/v1/user/invite/oidc/';
export const LINK_EID_PATH = '/api/v1/user/link/request/';
export const LINK_EID_CHECK_PATH = '/api/v1/user/link/check/';
export const LINK_EID_CONFIRM_PATH = '/api/v1/user/link/confirm/';
export const EMPLOYEES_INVITE_EID_CB_PATH = '/api/v1/auth/oidc/invite/callback/';
export const NEW_EMPLOYEE_CHECK_EMAIL_PATH = '/api/v1/organisation/user/check-email/';
export const AUDIT_LOGS_LIST_PATH = '/api/v1/audit/';
export const AUDIT_LOGS_CSV_EXPORT = '/api/v1/audit/csv-export/';
export const SERVER_TIME_PATH = '/api/v1/time';
export const FORM_GROUP_PATH = '/api/v1/afs/form_group/';
export const FORM_GROUP_DETAILS_PATH = (id: number) => `/api/v1/afs/form_group/${id}/`;
export const FORM_GROUP_ACTIVITY_PATH = (id: number) => `/api/v1/afs/form_group/${id}/activity`;
export const FORM_GROUP_FORM_PATH = (groupId: number, formId: number) =>
  `/api/v1/afs/form_group/${groupId}/form/${formId}`;
export const CONFIGURATION_PATH = '/api/v1/configuration';

export const QUERY_STALE_TIME = 30000; // 30 sec.
export const CONFIG_STALE_TIME = 1800000; // 30 min.

export const HTTP_AUTH_CODES = [401];
