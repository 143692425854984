import { getAdministrationOrganization } from '@models/constants/Configuration';
import { ConfigurationDTO, ConfigurationServicesDTO, ServerTimeDTO } from '@models/dtos/Configuration';
import { Configuration } from '@models/types/Configuration';
import { BackendPermissions } from '@models/types/Permission';
import { ServicesConfiguration } from '@models/types/ServicesConfiguration';
import { municipalityDTOParser } from '@services/api/parsers/Municipality';
import { enabledFeatureGroupForPermission } from '@utils/Permission';

import { organizationServicesDTOParser } from './Organization';
import { permissionDTOParser } from './Permission';

export const configurationServicesDTOParser = (servicesDTO: ConfigurationServicesDTO): ServicesConfiguration => {
  return {
    ...organizationServicesDTOParser(servicesDTO),
    relyingPartiesEnabled: Boolean(servicesDTO.enable_relying_parties),
  };
};

export const configurationDTOParser = (configurationDTO: ConfigurationDTO): Configuration => {
  const features = configurationServicesDTOParser(configurationDTO.features);
  const user = configurationDTO.user;
  const isSuperAdmin = user.is_superadmin;
  const administrationOrganization = getAdministrationOrganization(features);
  const userOrganizations = user.organisations.map((organizationDetails) => ({
    id: organizationDetails.id,
    name: organizationDetails.name,
    admin: organizationDetails.user_role.is_admin,
    municipality: organizationDetails.municipality
      ? municipalityDTOParser(organizationDetails.municipality)
      : undefined,
    permissions: [
      BackendPermissions.DashboardRead,
      ...organizationDetails.user_role.permissions.flatMap((permission) => {
        const servicesConfiguration = organizationServicesDTOParser(organizationDetails);
        const featureGroup = enabledFeatureGroupForPermission(permissionDTOParser(permission), features);
        if (!featureGroup) {
          return [];
        }
        const featureEnabledOnOrg = featureGroup.featureFlag ? servicesConfiguration[featureGroup.featureFlag] : true;
        const featureEnabledInConfig = featureGroup.featureFlag ? features[featureGroup.featureFlag] : true;
        if (!featureEnabledOnOrg || !featureEnabledInConfig) {
          return [];
        }
        return [`${permission.app}.${permission.name}.${permission.action}`];
      }),
    ],
  }));
  const organizations = isSuperAdmin ? [administrationOrganization, ...userOrganizations] : userOrganizations;
  return {
    isSuperAdmin,
    organizations,
    documentIds: configurationDTO.document_ids,
    features,
  };
};

export const serverTimeParser = (timeDTO: ServerTimeDTO): Date => {
  return new Date(timeDTO.time);
};
