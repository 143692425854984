import appConfig from '@appconf/config'; // REQUIRED! HAS to be imported AND used in this file!
import * as Sentry from '@sentry/react';

export const isSentryAvailable = Boolean(
  (process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_INIT_SENTRY) && !!appConfig.sentry.dsn
);

export const initSentry = () => {
  if (isSentryAvailable) {
    Sentry.init({
      environment: `${appConfig.flavor}_${window.location.hostname}`,
      dsn: appConfig.sentry.dsn,
      debug: process.env.NODE_ENV === 'development',
      release: `${appConfig.flavor}@${appConfig.release}`,
      autoSessionTracking: false,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
      ],
    });
  }
};
