import {
  CreateOrganizationDTO,
  DocumentNotificationDTO,
  DuplicateOrganizationDTO,
  EditOrganizationDTO,
  OrganizationCreatedEditedDTO,
  OrganizationDetailsDTO,
  OrganizationDocumentTypeDTO,
  OrganizationDTO,
  OrganizationServicesDTO,
} from '@models/dtos/Organization';
import {
  CreatedOrganization,
  DocumentsNotificationConfiguration,
  EditedOrganization,
  Organization,
  OrganizationDetails,
  OrganizationDocumentType,
  OrganizationType,
} from '@models/types/Organization';
import { OrganizationServicesConfiguration } from '@models/types/ServicesConfiguration';
import { TimeSpan } from '@models/types/TimeSpan';
import { municipalityDTOParser } from '@services/api/parsers/Municipality';
import { nonNil } from '@utils/filtering/NonNilFilter';
import moment from 'moment/moment';

import { addressDTOParser } from './Address';
import { documentTypeMapper, documentTypeParser } from './DocumentOrder';
import { roleDetailsDTOParser } from './Role';
import { notificationFrequencyMapper, notificationFrequencyParser } from './TimeSpan';

export const organizationTypeParser = (type: number): OrganizationType => {
  switch (type) {
    case 1:
      return OrganizationType.Administration;
    case 2:
      return OrganizationType.Government;
    case 3:
      return OrganizationType.Company;
    default:
      return OrganizationType.Unknown;
  }
};

export const organizationTypeMapper = (type: OrganizationType): number | undefined => {
  switch (type) {
    case OrganizationType.Administration:
      return 1;
    case OrganizationType.Government:
      return 2;
    case OrganizationType.Company:
      return 3;
    default:
      return undefined;
  }
};

export const organizationServicesDTOParser = (
  organizationServicesDTO: OrganizationServicesDTO
): OrganizationServicesConfiguration => {
  return {
    documentsEnabled: Boolean(organizationServicesDTO.enable_documents),
    documentRequestsEnabled: Boolean(organizationServicesDTO.enable_digital_delivery),
    verificationsEnabled: Boolean(organizationServicesDTO.enable_validation),
    newsEnabled: Boolean(organizationServicesDTO.enable_news),
    externalLinksEnabled: Boolean(organizationServicesDTO.enable_external_links),
    pollsEnabled: Boolean(organizationServicesDTO.enable_polls),
    voteInfoEnabled: Boolean(organizationServicesDTO.enable_voteinfo),
    siteAccessEnabled: Boolean(organizationServicesDTO.enable_site_access),
    formGroupsEnabled: Boolean(organizationServicesDTO.enable_form_groups),
  };
};

export const documentNotificationToDTOParser = (
  notificationConfig: DocumentsNotificationConfiguration,
  prefix: 'document' | 'digital_delivery'
): DocumentNotificationDTO => {
  if (!notificationConfig.notificationTimeSpan || notificationConfig.notificationTimeSpan === TimeSpan.Never) {
    return {
      [`${prefix}_notification_frequency`]: 0,
      [`${prefix}_notification_datetime`]: undefined,
      [`${prefix}_notification_roles`]: [],
    };
  }
  return {
    [`${prefix}_notification_frequency`]: notificationFrequencyMapper(notificationConfig.notificationTimeSpan),
    [`${prefix}_notification_datetime`]: notificationConfig.notificationDateTime?.toISOString(true),
    [`${prefix}_notification_roles`]: notificationConfig.notificationRoles,
  };
};

export const documentNotificationDTOParser = (
  organizationDetailsDTO: OrganizationDetailsDTO
): DocumentsNotificationConfiguration => {
  const timeSpan = notificationFrequencyParser(organizationDetailsDTO.document_notification_frequency);
  const parsedDate = organizationDetailsDTO.document_notification_datetime
    ? moment(organizationDetailsDTO.document_notification_datetime)
    : undefined;
  return {
    notificationTimeSpan: timeSpan,
    notificationDateTime: parsedDate,
    notificationRoles: organizationDetailsDTO.document_notification_roles,
  };
};

export const documenRequeststNotificationDTOParser = (
  organizationDetailsDTO: OrganizationDetailsDTO
): DocumentsNotificationConfiguration => {
  const timeSpan = notificationFrequencyParser(organizationDetailsDTO.digital_delivery_notification_frequency);
  const parsedDate = organizationDetailsDTO.digital_delivery_notification_datetime
    ? moment(organizationDetailsDTO.digital_delivery_notification_datetime)
    : undefined;
  return {
    notificationTimeSpan: timeSpan,
    notificationDateTime: parsedDate,
    notificationRoles: organizationDetailsDTO.digital_delivery_notification_roles,
  };
};

export const organizationDTOParser = (organizationDTO: OrganizationDTO): Organization => {
  return {
    id: organizationDTO.id,
    name: organizationDTO.name,
    type: organizationTypeParser(organizationDTO.organisation_type),
    roles: organizationDTO.roles.map((role) => roleDetailsDTOParser(role)),
    municipality: organizationDTO.municipality,
  };
};

export const organizationDetailsDTOParser = (organizationDetailsDTO: OrganizationDetailsDTO): OrganizationDetails => {
  const documentNotifications = documentNotificationDTOParser(organizationDetailsDTO);
  const documentRequestsNotifications = documenRequeststNotificationDTOParser(organizationDetailsDTO);
  const municipality = organizationDetailsDTO.municipality
    ? municipalityDTOParser(organizationDetailsDTO.municipality)
    : undefined;

  const ddpPrefix = organizationDetailsDTO.digital_delivery_reference_number_prefix;
  return {
    id: organizationDetailsDTO.id,
    name: organizationDetailsDTO.name,
    type: organizationTypeParser(organizationDetailsDTO.organisation_type),
    address: addressDTOParser({
      street: organizationDetailsDTO.address_line_1,
      extra: organizationDetailsDTO.address_line_2,
      zip_code: organizationDetailsDTO.zip_code,
      city: organizationDetailsDTO.city,
    }),
    phoneNumber: organizationDetailsDTO.phone_number,
    email: organizationDetailsDTO.email,
    website: organizationDetailsDTO.website_url,
    showInApp: organizationDetailsDTO.show_in_app,
    services: organizationServicesDTOParser(organizationDetailsDTO),
    roles: organizationDetailsDTO.roles.map((roleDTO) => roleDetailsDTOParser(roleDTO)),
    municipality: municipality,
    selectedDocumentTypes: organizationDetailsDTO.document_types.map((docType) =>
      organizationDocumentTypeDTOParser(docType)
    ),
    documentNotifications,
    documentRequestsNotifications,
    documentRequestReferenceNumberPrefix: ddpPrefix && ddpPrefix.length ? ddpPrefix : undefined,
    documentRequestReferenceNumberStartNumber: organizationDetailsDTO.digital_delivery_reference_start_number,
  };
};

export const organizationCreatedEditedDTOParser = (
  organizationCreatedEditedDTO: OrganizationCreatedEditedDTO
): OrganizationDetails => {
  return organizationDetailsDTOParser({
    ...organizationCreatedEditedDTO,
    roles: [],
    document_types: [],
  });
};

export const organizationDocumentTypeDTOParser = (
  organizationDocumentTypeDTO: OrganizationDocumentTypeDTO
): OrganizationDocumentType => {
  return {
    id: organizationDocumentTypeDTO.id,
    createdAt: organizationDocumentTypeDTO.created_at,
    type: documentTypeParser(organizationDocumentTypeDTO.document_type),
  };
};

export const organizationServicesConfigurationToDTOParser = (
  organizationServicesConfiguration?: Partial<OrganizationServicesConfiguration>
): Partial<OrganizationServicesDTO> => {
  return {
    enable_documents: organizationServicesConfiguration?.documentsEnabled,
    enable_digital_delivery: organizationServicesConfiguration?.documentRequestsEnabled,
    enable_validation: organizationServicesConfiguration?.verificationsEnabled,
    enable_news: organizationServicesConfiguration?.newsEnabled,
    enable_external_links: organizationServicesConfiguration?.externalLinksEnabled,
    enable_polls: organizationServicesConfiguration?.pollsEnabled,
    enable_voteinfo: organizationServicesConfiguration?.voteInfoEnabled,
    enable_site_access: organizationServicesConfiguration?.siteAccessEnabled,
    enable_form_groups: organizationServicesConfiguration?.formGroupsEnabled,
  };
};

export const createdOrganizationToDTOParser = (organization: CreatedOrganization): CreateOrganizationDTO => {
  const documentNotifications = organization.documents
    ? documentNotificationToDTOParser(organization.documents, 'document')
    : undefined;
  const documentRequestsNotifications = organization.documentRequests
    ? documentNotificationToDTOParser(organization.documentRequests, 'digital_delivery')
    : undefined;
  return {
    name: organization.name,
    organisation_type: organizationTypeMapper(organization.type) ?? 0,
    address_line_1: organization.address.street,
    address_line_2: organization.address.extra,
    zip_code: organization.address.postcode,
    city: organization.address.city ?? organization.address.location ?? '',
    phone_number: organization.phoneNumber,
    email: organization.email,
    website_url: organization.website,
    enable_documents: organization.services.documentsEnabled,
    enable_digital_delivery: organization.services.documentRequestsEnabled,
    enable_validation: organization.services.verificationsEnabled,
    show_in_app: organization.verifications?.showInApp ?? false,
    enable_news: organization.services.newsEnabled,
    enable_external_links: organization.services.externalLinksEnabled,
    enable_polls: organization.services.pollsEnabled,
    enable_voteinfo: organization.services.voteInfoEnabled,
    enable_site_access: organization.services.siteAccessEnabled,
    enable_form_groups: organization.services.formGroupsEnabled,
    roles: organization.roles.map((role) => role.id),
    document_types: organization.documents?.documentTypes.map(documentTypeMapper).filter(nonNil) ?? [],
    municipality: organization.municipality,
    digital_delivery_reference_number_prefix: organization.documentRequests?.prefix,
    digital_delivery_reference_start_number: organization.documentRequests?.startNumber,
    ...documentNotifications,
    ...documentRequestsNotifications,
  };
};

export const editedOrganizationToDTOParser = (organization: EditedOrganization): EditOrganizationDTO => {
  const createdOrgDTO = createdOrganizationToDTOParser(organization);
  return createdOrgDTO;
};

export const organizationDuplicateDTO = (organisationId: number): DuplicateOrganizationDTO => {
  return {
    organisation: organisationId,
  };
};
