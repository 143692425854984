import { CONFIG_STALE_TIME, CONFIGURATION_PATH, SERVER_TIME_PATH } from '@models/constants/API';
import { Configuration } from '@models/types/Configuration';
import { GET } from '@services/api/fetch';
import { configurationDTOParser, serverTimeParser } from '@services/api/parsers/Configuration';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export type ConfigFetcher = (employeeId?: number) => Promise<Configuration>;

export const getConfig: ConfigFetcher = async () => {
  return GET(CONFIGURATION_PATH).then(configurationDTOParser);
};

export const useConfiguration = (configFetcher: ConfigFetcher = getConfig) => {
  return useQuery({
    queryKey: ['configuration'],
    queryFn: () => configFetcher(),
    staleTime: CONFIG_STALE_TIME,
    placeholderData: keepPreviousData,
    retry: 1,
  });
};

export type TimeFetcher = () => Promise<Date>;

export const getTime: TimeFetcher = async () => {
  return GET(SERVER_TIME_PATH).then(serverTimeParser);
};

export const useServerTime = (timeFetcher: TimeFetcher = getTime) => {
  return useQuery({
    queryKey: ['time'],
    queryFn: () => timeFetcher(),
    staleTime: CONFIG_STALE_TIME,
    placeholderData: keepPreviousData,
    enabled: false,
  });
};
