export enum EventHistoryAction {
  ProfileCreated = 'eventHistory.action.profile.created',
  ProfileMigrated = 'eventHistory.action.profile.migrated',
  ProfileUpdated = 'eventHistory.action.profile.updated',

  ValidationCreated = 'eventHistory.action.validation.created',
  ValidationRejected = 'eventHistory.action.validation.rejected',
  ValidationAccepted = 'eventHistory.action.validation.accpeted',
  ValidationRevoked = 'eventHistory.action.validation.revoked',
  ValidationReinstated = 'eventHistory.action.validation.reinstated',
  ValidationExtended = 'eventHistory.action.validation.extended',

  EmployeeCreated = 'eventHistory.action.employee.created',
  EmployeeUpdated = 'eventHistory.action.employee.updated',
  EmployeeOrganizationAdded = 'eventHistory.action.employee.organizationAdded',
  EmployeeOrganizationRemoved = 'eventHistory.action.employee.organizationRemoved',
  EmployeeRoleChanged = 'eventHistory.action.employee.roleChanged',

  Unknown = 'eventHistory.action.unknown',
}

export interface HistoryEvent {
  id: number;
  action: EventHistoryAction;
  datetime: Date;
  employee: string;
}
