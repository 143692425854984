import { AppConfig, AppFlavor } from '@appconf/models';

import { shThemeColors, shThemeConfig, shThemeImages } from './theme';

const shConfig: AppConfig = {
  flavor: AppFlavor.sh,
  release: process.env.REACT_APP_RELEASE ?? 'dev',
  mobileAppName: 'Schaffhauser eID+',
  mobileAppNameShort: 'eID+',
  name: 'Desk',
  copyright: '© {date} - Procivis AG',
  sentry: {
    dsn: 'https://4d42f28b3b8842c8a82de43b716d5c25@o153694.ingest.sentry.io/5851084',
  },
  theme: {
    colors: shThemeColors,
    images: shThemeImages,
    config: shThemeConfig,
  },
  supportedLanguages: ['en', 'de'],
};

export default shConfig;
