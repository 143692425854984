/*
 * Only for models used local to the "settings" folder.
 * For everything else use the "../models" folder.
 */

import { TLanguage } from '@models/types/Language';
import { ThemeConfig as AntThemeConfig } from 'antd/lib/config-provider/context';

export enum AppFlavor {
  procivis = 'procivis',
  sh = 'sh',
  zug = 'zug',
  ar = 'ar',
  vfs = 'vfs',
}

export interface AppConfig {
  flavor: AppFlavor;
  release: string;
  mobileAppName: string;
  mobileAppNameShort: string;
  name: string;
  copyright: string;
  sentry: SentryConfig;
  theme: ThemeConfig;
  supportedLanguages: TLanguage[];
}

// --- Sentry

export interface SentryConfig {
  dsn?: string;
}

// --- Theme
// eZug Primary Variations: ["#404040", "#333333", "#262626", "#1a1a1a", "#0d0d0d", "#000000", "#000000", "#000000", "#000000", "#000000"]
// eZug Variant Variations: ["#f0fbff", "#e3f6ff", "#bae7ff", "#91d5ff", "#69c0ff", "#40a9ff", "#2b85d9", "#1b64b3", "#0e478c", "#093066"]

export interface ThemeConfig {
  colors: ThemeColors;
  images: ThemeImages;
  config: AntThemeConfig;
}

export interface ThemeColors {
  neutral: string;
  primary: string;
  primaryVariant: string;
  onPrimary: string;
  onPrimaryVariant: string;
  text: string; // Should be the same as the "@text-color" AntD less variable
  background: string;
  componentBackground: string;
  orgHighlight: string;
  orgAdminHighlight: string;
}

export interface ThemeImages {
  logoURI?: string;
  loginBgURI?: string;
}
