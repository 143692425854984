export enum PermissionApp {
  Dashboard = 'dashboard',
  Organizations = 'organisations',
  Documents = 'documents',
  DocumentRequests = 'digital_delivery',
  Validation = 'validation',
  VoteInfo = 'voteinfo',
  News = 'news',
  ExternalLinks = 'external_links',
  Polls = 'polls',
  Access = 'site_access',
  FormGroups = 'form_groups',
  AdministrationDashboard = 'administrationDashboard',
  AdministrationOrganizations = 'administrationOrganisations',
  AdministrationRelyingParties = 'administrationRelyingParties',
  AdministrationAuditLogs = 'administrationAuditLogs',
  AdministrationDocumentRequests = 'administrationDocumentRequests',
}

export enum PermissionName {
  Dashboard = 'dashboard',
  User = 'user',
  Request = 'request',
  DeliveryRequest = 'deliveryrequest',
  Validation = 'validation',
  Voteday = 'voteday',
  Proposal = 'proposal',
  Excuse = 'excuse',
  News = 'news',
  ExternalLink = 'external_link',
  Poll = 'poll',
  Organization = 'organisation',
  Role = 'role',
  RelyingParty = 'relyingParty',
  Access = 'site_access',
  PublicAccess = 'public_site_access',
  AuditLogs = 'auditLogs',
  FormGroups = 'form_groups',
  Forms = 'forms',
}

export enum PermissionType {
  None = 'permission.none',
  Read = 'permission.read',
  Edit = 'permission.edit',
}

export interface Permission {
  app: PermissionApp;
  name: PermissionName;
  type: PermissionType;
}

export enum BackendPermissionAction {
  Read = 'view',
  Edit = 'change',
}

export const BackendPermissions = {
  DashboardRead: `${PermissionApp.Dashboard}.${PermissionName.Dashboard}.${BackendPermissionAction.Read}`,
  OrganizationsUsersRead: `${PermissionApp.Organizations}.${PermissionName.User}.${BackendPermissionAction.Read}`,
  OrganizationsUsersEdit: `${PermissionApp.Organizations}.${PermissionName.User}.${BackendPermissionAction.Edit}`,
  DocumentOrdersRead: `${PermissionApp.Documents}.${PermissionName.Request}.${BackendPermissionAction.Read}`,
  DocumentOrdersEdit: `${PermissionApp.Documents}.${PermissionName.Request}.${BackendPermissionAction.Edit}`,
  DocumentRequestsRead: `${PermissionApp.DocumentRequests}.${PermissionName.DeliveryRequest}.${BackendPermissionAction.Read}`,
  DocumentRequestsEdit: `${PermissionApp.DocumentRequests}.${PermissionName.DeliveryRequest}.${BackendPermissionAction.Edit}`,
  ValidationsRead: `${PermissionApp.Validation}.${PermissionName.Validation}.${BackendPermissionAction.Read}`,
  ValidationsEdit: `${PermissionApp.Validation}.${PermissionName.Validation}.${BackendPermissionAction.Edit}`,
  VoteInfoRead: `${PermissionApp.VoteInfo}.${PermissionName.Voteday}.${BackendPermissionAction.Read}`,
  VoteInfoEdit: `${PermissionApp.VoteInfo}.${PermissionName.Voteday}.${BackendPermissionAction.Edit}`,
  VoteProposalsEdit: `${PermissionApp.VoteInfo}.${PermissionName.Proposal}.${BackendPermissionAction.Edit}`,
  VoteExcuseRead: `${PermissionApp.VoteInfo}.${PermissionName.Excuse}.${BackendPermissionAction.Read}`,
  NewsRead: `${PermissionApp.News}.${PermissionName.News}.${BackendPermissionAction.Read}`,
  NewsEdit: `${PermissionApp.News}.${PermissionName.News}.${BackendPermissionAction.Edit}`,
  ExternalLinksRead: `${PermissionApp.ExternalLinks}.${PermissionName.ExternalLink}.${BackendPermissionAction.Read}`,
  ExternalLinksEdit: `${PermissionApp.ExternalLinks}.${PermissionName.ExternalLink}.${BackendPermissionAction.Edit}`,
  PollsRead: `${PermissionApp.Polls}.${PermissionName.Poll}.${BackendPermissionAction.Read}`,
  PollsEdit: `${PermissionApp.Polls}.${PermissionName.Poll}.${BackendPermissionAction.Edit}`,
  SiteAccessRead: `${PermissionApp.Access}.${PermissionName.Access}.${BackendPermissionAction.Read}`,
  SiteAccessEdit: `${PermissionApp.Access}.${PermissionName.Access}.${BackendPermissionAction.Edit}`,
  PublicSiteAccessRead: `${PermissionApp.Access}.${PermissionName.PublicAccess}.${BackendPermissionAction.Read}`,
  PublicSiteAccessEdit: `${PermissionApp.Access}.${PermissionName.PublicAccess}.${BackendPermissionAction.Edit}`,
  FormGroupsRead: `${PermissionApp.FormGroups}.${PermissionName.FormGroups}.${BackendPermissionAction.Read}`,
  FormGroupsEdit: `${PermissionApp.FormGroups}.${PermissionName.FormGroups}.${BackendPermissionAction.Edit}`,
  FormGroupFormsRead: `${PermissionApp.FormGroups}.${PermissionName.Forms}.${BackendPermissionAction.Read}`,
  FormGroupFormsEdit: `${PermissionApp.FormGroups}.${PermissionName.Forms}.${BackendPermissionAction.Edit}`,

  // super admin
  AdministrationDashboardRead: `${PermissionApp.AdministrationDashboard}.${PermissionName.Dashboard}.${BackendPermissionAction.Read}`,
  AdministrationOrganizationsRead: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Organization}.${BackendPermissionAction.Read}`,
  AdministrationOrganizationsEdit: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Organization}.${BackendPermissionAction.Edit}`,
  AdministrationRolesRead: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Role}.${BackendPermissionAction.Read}`,
  AdministrationRolesEdit: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Role}.${BackendPermissionAction.Edit}`,
  AdministrationUsersRead: `${PermissionApp.AdministrationOrganizations}.${PermissionName.User}.${BackendPermissionAction.Read}`,
  AdministrationUsersEdit: `${PermissionApp.AdministrationOrganizations}.${PermissionName.User}.${BackendPermissionAction.Edit}`,
  AdministrationRelyingPartiesRead: `${PermissionApp.AdministrationRelyingParties}.${PermissionName.RelyingParty}.${BackendPermissionAction.Read}`,
  AdministrationRelyingPartiesEdit: `${PermissionApp.AdministrationRelyingParties}.${PermissionName.RelyingParty}.${BackendPermissionAction.Edit}`,
  AdministrationAuditLogsRead: `${PermissionApp.AdministrationAuditLogs}.${PermissionName.AuditLogs}.${BackendPermissionAction.Read}`,
  AdministrationDocumentRequestsEdit: `${PermissionApp.AdministrationDocumentRequests}.${PermissionName.DeliveryRequest}.${BackendPermissionAction.Edit}`,
};
